<template>
  <div id="accounts">
    <div className="cc-list-content">
      <div className="cc-search-wrap">
        <a-form ref="search_form" :model="searchForm" layout="inline">
          <a-form-item label="Email Address">
            <a-input
              v-model:value="searchForm.email"
              placeholder="Email Address"
            />
          </a-form-item>
          <a-form-item label="Full Name">
            <a-form-item class="double-child">
              <a-input
                v-model:value="searchForm.firstName"
                placeholder="First Name"
              />
            </a-form-item>
            <a-form-item class="double-child">
              <a-input
                v-model:value="searchForm.lastName"
                placeholder="Last Name"
              />
            </a-form-item>
          </a-form-item>
          <a-form-item label="Mobile Number">
            <a-input
              v-model:value="searchForm.mobile"
              placeholder="Mobile Number"
            />
          </a-form-item>
          <a-form-item label="Status">
            <a-select
              v-model:value="searchForm.status"
              placeholder="Please Select"
            >
              <a-select-option :key="0">enable</a-select-option>
              <a-select-option :key="1">disable</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item style="margin-left: 5px">
            <a-button
              style="margin-right: 10px"
              type="primary"
              @click="getChildAccountTable()"
            >
              <search-outlined />
              Search
            </a-button>
            <a-button @click="reset_searchForm">
              <sync-outlined />
              Reset
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="addChildAccount">
        <a-button type="primary" @click="add_ChildAccount(0)">
          <usergroup-add-outlined style="font-size: 18px" />
          Create Sub-account
        </a-button>
      </div>
      <a-table
        :dataSource="dataList"
        :columns="columns"
        :pagination="pagination"
        @change="changePages"
      >
        <template #bodyCell="{ column, text }">
          <div v-if="column.title == 'Operate'">
            <a-space>
              <a href="javascript:;" @click="add_ChildAccount(1, text)">Edit</a>
              <a-popconfirm
                title="Sure to Delete?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="delet_ChildAccount(text.id)"
              >
                <a href="javascript:;">Delete</a>
              </a-popconfirm>
            </a-space>
          </div>
          <div v-if="column.title == 'Status'">
            <a-switch
              checkedChildren="Enable"
              unCheckedChildren="Disable"
              :checkedValue="0"
              :unCheckedValue="1"
              :checked="text.status"
              @click="switchChange($event, text)"
            >
            </a-switch>
          </div>
        </template>
      </a-table>
    </div>

    <a-modal
      :visible="Boolean(showModal)"
      :title="showModal"
      @cancel="closeModal"
      centered
      :footer="null"
      forceRender
    >
      <a-form
        :model="submitForm"
        ref="submitForm"
        :rules="rules"
        :labelCol="{ span: 6 }"
        :wrapperCol="{ span: 18 }"
      >
        <a-form-item
          label="Mobile Number"
          :rules="[{ required: true }]"
          style="display: flex; margin: 0"
        >
          <!-- <a-input-group> -->
          <a-row :gutter="[3, 0]">
            <a-col :span="8">
              <a-form-item calss="double-child" name="areaCode">
                <a-input
                  v-model:value="submitForm.areaCode"
                  placeholder=" Country Code"
                />
              </a-form-item>
            </a-col>
            <a-col :span="16">
              <a-form-item calss="double-child" name="mobile">
                <a-input
                  v-model:value="submitForm.mobile"
                  placeholder="Mobile Number"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <!-- </a-input-group> -->
        </a-form-item>
        <!-- <a-form-item label="Area code" name="areaCode">
          <a-input
            v-model:value="submitForm.areaCode"
            placeholder="Area code"
          />
        </a-form-item>
        <a-form-item label="Phone" name="mobile">
          <a-input v-model:value="submitForm.mobile" placeholder="Phone no." />
        </a-form-item> -->
        <a-form-item label="Email" name="email">
          <a-input
            v-model:value="submitForm.email"
            placeholder="Email"
          />
        </a-form-item>
        <a-form-item
          label="Full Name"
          :rules="[{ required: true }]"
          style="display: flex; margin: 0"
        >
          <a-form-item
            name="firstName"
            style="display: inline-block; width: 171px"
          >
            <a-input
              v-model:value="submitForm.firstName"
              placeholder="First Name"
            /> </a-form-item
          >&ensp;
          <a-form-item
            name="lastName"
            style="display: inline-block; width: 171px"
          >
            <a-input
              v-model:value="submitForm.lastName"
              placeholder="Last Name"
            />
          </a-form-item>
        </a-form-item>
        <a-form-item label="Password" name="password">
          <a-input-password
            v-model:value="submitForm.password"
            placeholder="Password"
          />
        </a-form-item>
        <a-form-item label="Status" name="status">
          <a-radio-group v-model:value="submitForm.status">
            <a-radio :value="0">Enable</a-radio>
            <a-radio :value="1">Disable</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
      <div class="footer">
        <a-button style="margin-right: 30px" @click="closeModal"
          >Cancel</a-button
        >
        <a-button
          type="primary"
          @click="submit(showModal == 'Create Sub-account' ? 0 : 1)"
          >Confirm</a-button
        >
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  childAccountTable,
  addChildAccount,
  editChildAccount,
  delChildAccount,
  limitChildAccount,
} from '@/api'
import { defineComponent } from 'vue'
import {
  SyncOutlined,
  SearchOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons-vue'

const columns = [
  {
    title: 'Email',
    dataIndex: 'email',
    align: 'center',
  },
  {
    title: 'Name',
    customRender: ({ text }) => {
      return `${text.firstName} ${text.lastName}`
    },
    align: 'Center',
  },
  {
    title: 'Phone',
    dataIndex: 'mobile',
    align: 'center',
  },
  {
    title: 'Status',
    align: 'center',
  },
  {
    title: 'Operate',
    align: 'center',
  },
]
const emailValidator = (rule, value) => {
  const reg =
    /^([a-zA-Z0-9\S]{1,})+\@+(([a-zA-Z0-9\S]{1,})\.([a-zA-Z0-9\S]{1,})|([a-zA-Z0-9\S]{1,})\.([a-zA-Z0-9\S]{1,})+(\S){1,})$/
  if (value == '') {
    return Promise.reject(new Error('Please Fill In Email'))
  } else if (!reg.test(value)) {
    return Promise.reject(new Error('Email Format Is Incorrect'))
  } else {
    return Promise.resolve()
  }
}
export default defineComponent({
  components: {
    SyncOutlined,
    SearchOutlined,
    UsergroupAddOutlined,
  },
  data() {
    return {
      showModal: false,
      dataList: [],
      searchForm: {
        areaCode: undefined, //区号
        associationId: undefined, //合作方ID
        email: undefined, //邮箱
        firstName: undefined, //姓
        // id: undefined, //子账号id（修改传，新增不传）
        lastName: undefined, //名
        mobile: undefined, //手机号
        pageNum: 1, //页码
        pageSize: 10, //页大小
        parentId: undefined, //父ID
        password: undefined, //密码
        s_status: undefined, //状态0启用 1 禁用
      },
      submitForm: {
        areaCode: undefined, //区号
        associationId: undefined, //合作方ID
        email: undefined, //邮箱
        firstName: undefined, //姓
        // id: undefined, //子账号id（修改传，新增不传）
        lastName: undefined, //名
        mobile: undefined, //手机号
        parentId: undefined, //父ID
        password: undefined, //密码
        status: 0, //状态0启用 1 禁用
      },
      pagination: {
        showTotal: (total) => `total ${total}`,
        total: 0,
        pageSize: 10,
        hideOnSinglePage: true,
        current: 1,
        showSizeChanger: true,
      },
      emailValidator,
      columns,
      rules: {
        areaCode: [
          { required: true, message: 'Can not be empty', trigger: 'blur' },
        ],
        // associationId: [
        //   { , message: '不能为空', trigger: 'blur' },
        // ],
        email: [
          {
            required: true,
            validator: emailValidator,
            trigger: 'blur',
          },
        ],
        firstName: [
          { required: true, message: 'Can not be empty', trigger: 'blur' },
        ],
        lastName: [
          { required: true, message: 'Can not be empty', trigger: 'blur' },
        ],
        mobile: [
          { required: true, message: 'Can not be empty', trigger: 'blur' },
        ],
        password: [
          { required: true, message: 'Can not be empty', trigger: 'blur' },
        ],
        status: [
          { required: true, message: 'must choose one', trigger: 'blur' },
        ],
      },
    }
  },
  created() {
    this.getChildAccountTable()
  },
  methods: {
    changePages(pages) {
      this.searchForm.pageNum = pages.current
      this.searchForm.pageSize = pages.pageSize
      this.getChildAccountTable()
    },
    getChildAccountTable() {
      childAccountTable(this.searchForm).then((res) => {
        this.dataList = res.data.records
        this.pagination.total = res.data.total
        this.pagination.pageSize = res.data.size
        this.pagination.current = res.data.current
      })
    },
    // 关闭编辑/新增弹框
    closeModal() {
      this.showModal = false
      this.$refs.submitForm.resetFields()
      this.submitForm = {}
    },
    // 打开编辑/新增 弹框  openType == 0 新增  openType == 1 编辑
    add_ChildAccount(openType, data) {
      if (openType) {
        this.submitForm = JSON.parse(JSON.stringify(data))
        console.log(this.submitForm)
        this.showModal = 'Edit Sub-account'
      } else {
        this.showModal = 'Create Sub-account'
      }
    },
    //搜索表单重置
    reset_searchForm() {
      // this.$refs.search_form.resetFields()
      this.searchForm = {
        pageNum: 1,
        pageSize: 10,
      }
      this.getChildAccountTable()
    },
    //  删除一个子账号
    delet_ChildAccount(id) {
      delChildAccount(id).then((res) => {
        this.$message.success('successfully deleted!')
        this.getChildAccountTable()
      })
    },
    switchChange(v, t) {
      this.switchLoading = true
      limitChildAccount({ id: t.id, status: v })
        .then((res) => {
          t.status = v
        })
        .catch((err) => {
          this.$message.warning('Setup failed！')
        })
    },
    // 添加子账号    type == 0 创建  type == 1 编辑
    submit(type) {
      this.$refs.submitForm
        .validate()
        .then((res) => {
          this.submitForm.parentId = this.$store.getters.userData.mobile
          this.submitForm.associationId =
            this.$store.getters.userData.associationId
          if (!type) {
            addChildAccount(this.submitForm).then((res) => {
              this.$message.success('Added successfully!')
              this.closeModal()
              this.getChildAccountTable()
            })
          } else {
            editChildAccount(this.submitForm).then((res) => {
              this.$message.success('Edited successfully!')
              this.closeModal()
              this.getChildAccountTable()
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
})
</script>

<style lang="less" scoped>
@import '../../assets/cc-list-tap-container.less';
@import '../../assets/cc-search-wrap.less';
#accounts {
  .addChildAccount {
    margin-bottom: 10px;
  }
}
.footer {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
